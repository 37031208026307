<template>
    <el-form :model="userInfo" :rules="ruleValidate" ref="editForm">
        <el-form-item label="姓名" prop="trueName">
            <el-input v-model.trim="userInfo.trueName" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="userInfo.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone" >
            <el-input v-model.trim="userInfo.phone" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
            <el-input v-model.trim="userInfo.email" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="update('editForm')">保存修改</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        watch: {
            user(val) {
                this.userInfo = val;
            },
        },
        data() {
            return {
                userInfo: {

                },
                ruleValidate: {
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                },
            }
        },
        methods: {
            update: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "system/admin/save";
                        this.userInfo.updateUid = this.appCookies.getCookieUID();
                        this.userInfo.updateUname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.userInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
        }
    }
</script>
