<template>
    <div class="app-container">
        <div>
            <el-row :gutter="20" style="height: 100%">
                <el-col :span="6" style="height: 100%">
                    <user-card :user="info"/>
                </el-col>
                <el-col :span="18" style="height: 100%">
                    <el-card>
                        <el-tabs v-model="activeTab">
                            <el-tab-pane label="个人信息" name="activity">
                                <activity :user="info"/>
                            </el-tab-pane>
                            <el-tab-pane label="修改密码" name="account">
                                <account :user="info"/>
                            </el-tab-pane>
                        </el-tabs>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import UserCard from './components/UserCard'
    import Activity from './components/Activity'
    import Account from './components/Account'

    export default {
        name: 'Profile',
        components: {
            UserCard,
            Activity,
            Account
        },
        data() {
            return {
                info: {},
                activeTab: 'activity'
            }
        },
        created() {
            this.getById()
        },
        methods: {
            getById: function () {
                let url = 'system/admin/getById?userId=';
                this.apis.get(url + this.appCookies.getCookieUID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>
