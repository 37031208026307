<template>
    <el-form :model="changeInfo" :rules="ruleValidate" ref="editForm">
        <el-form-item label="原密码" prop="oldPwd">
            <el-input v-model.trim="changeInfo.oldPwd" placeholder="请输入原密码" show-password/>
        </el-form-item>
        <el-form-item label="新密码" prop="userPwd">
            <el-input v-model.trim="changeInfo.userPwd" placeholder="请输入新密码" show-password/>
        </el-form-item>
        <el-form-item label="确认新密码" prop="userPwd2">
            <el-input v-model.trim="changeInfo.userPwd2"  placeholder="请再次输入新密码" show-password/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="update('editForm')">保存修改</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import { encryptDes, vlaidPassword } from '@js/des'
    export default {
        data() {
            return {
                changeInfo: {
                    oldPwd: '',
                    userPwd: '',
                    newPwd: '',
                    newPwd2: '',
                },
                ruleValidate: {
                    oldPwd: [
                        { required: true, message: '请输入原密码', trigger: 'blur' }
                    ],
                    userPwd: [
                        { required: true, message: '请输入新密码', trigger: 'blur' }
                    ],
                    userPwd2: [
                        { required: true, message: '请再次输入新密码', trigger: 'blur' }
                    ],
                },
            }
        },
        methods: {
            update: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (!vlaidPassword(this.changeInfo.userPwd)) {
                            this.$message({
                                message: '您的密码强度太低（必须包含字母、数字和特称字符，至少8个字符，最多20个字符），请重新输入！', showClose: true, offset: 200, type: 'error'
                            });
                            this.changeInfo.userPwd = '';
                            return;
                        }
                        if (this.changeInfo.userPwd !== this.changeInfo.userPwd2) {
                            this.$message({
                                message: '两次输入的新密码不一致', showClose: true, offset: 200, type: 'error'
                            });
                            return;
                        }
                        let formData = {
                            oldPwd: encryptDes(this.changeInfo.oldPwd),
                            userPwd: encryptDes(this.changeInfo.userPwd),
                            userId: this.appCookies.getCookieUID()
                        };
                        let url = "system/admin/changePwd";
                        this.apis.postForm(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '密码修改成功', showClose: true, offset: 200, type: 'success'
                                    });
                                    this.changeInfo = {
                                        userPwd: '',
                                        newPwd: '',
                                        newPwd2: '',
                                    }
                                } else {
                                    this.$message({
                                        message: res.msg, showClose: true, offset: 200, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            vlaidPassword: function (pwd) {
                // let pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6, 20}');
                let regex = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/
                if (!regex.test(pwd)) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }
</script>
