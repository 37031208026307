<template>
    <el-card style="margin-bottom:20px;">
        <div slot="header" class="clearfix">
            <span>个人信息</span>
        </div>

        <div class="user-profile">
            <div class="box-center">
                <pan-thumb :image="avatar" :height="'100px'" :width="'100px'" :hoverable="false">
                </pan-thumb>
            </div>
            <div class="box-center">
                <div class="user-name text-center">{{ user.trueName }}</div>
<!--                <div class="user-role text-center text-muted">{{ user.orgName }}</div>-->
            </div>
        </div>

        <div class="user-bio">
<!--            <div class="user-education user-bio-section">-->
<!--                <div class="user-bio-section-header">-->
<!--                    <svg-icon icon-class="education"/>-->
<!--                    <span>角色</span></div>-->
<!--                <div class="user-bio-section-body">-->
<!--                    <div class="text-muted">-->
<!--                        {{user.role.roleName}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="user-skills user-bio-section">
                <div class="user-bio-section-header">
                    <svg-icon icon-class="skill"/>
                    <span>个人信息</span></div>
                <div class="user-bio-section-body">
                    <div class="progress-item" >
                        <span>姓名：</span>{{user.trueName}}
                    </div>
                    <div class="progress-item" >
                        <span>单位名称：</span>{{user.orgName}}
                    </div>
                    <div class="progress-item">
                        <span>联系电话：</span>{{user.phone}}
                    </div>
                    <div class="progress-item">
                        <span>邮箱：</span>{{user.email}}
                    </div>
                </div>
            </div>
        </div>
    </el-card>
</template>

<script>
    import PanThumb from '@/components/PanThumb'

    export default {
        components: { PanThumb },
        props: {
            user: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                avatar: require('../../../assets/images/avatar.png')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box-center {
        margin: 0 auto;
        display: table;
    }

    .text-muted {
        color: #777;
    }

    .user-profile {
        .user-name {
            font-weight: bold;
        }

        .box-center {
            padding-top: 10px;
        }

        .user-role {
            padding-top: 10px;
            font-weight: 400;
            font-size: 14px;
        }

        .box-social {
            padding-top: 30px;

            .el-table {
                border-top: 1px solid #dfe6ec;
            }
        }

        .user-follow {
            padding-top: 20px;
        }
    }

    .user-bio {
        margin-top: 20px;
        color: #606266;

        span {
            padding-left: 4px;
        }

        .user-bio-section {
            font-size: 14px;
            padding: 15px 0;

            .user-bio-section-header {
                border-bottom: 1px solid #dfe6ec;
                padding-bottom: 10px;
                margin-bottom: 10px;
                font-weight: bold;
            }
            .user-bio-section-body {
                padding-bottom: 10px;
                margin-bottom: 10px;
                span {
                    font-size: 14px;
                    line-height: 1.5;
                }
            }
            .progress-item {
                margin-bottom: 10px;
                font-size: 14px;
                span {
                    font-weight: bold;
                }
            }
        }
    }
</style>
